import React from 'react';
import Layout from '../components/layout';
import ContentTitle from '../components/ContentTitle';

const NotFoundPage = () => (
  <Layout>
    <ContentTitle>Oops! There is nothing here!</ContentTitle>
    <p>Whatever you were looking for is no longer here. Sorry about that!</p>
  </Layout>
);

export default NotFoundPage;
